import React, { useContext, Dispatch, SetStateAction } from "react";
import styled from "styled-components/macro";
import { BasketItem } from "./BasketItem";
import { BookButton } from "../../pages/booking/components/payment/Payment";
import { APP_ROUTES } from "../../config/router.constants";
import { BasketContext } from "../../config/basketContext";
import { useHistory } from "react-router";

interface BasketProps {
  isOpen: boolean;
  handleClickCheckoutButton: Dispatch<SetStateAction<boolean>>;
}

export const Basket: React.FC<BasketProps> = ({
  isOpen,
  handleClickCheckoutButton
}) => {
  const basketState = useContext(BasketContext);
  const history = useHistory();
  return (
    <Container open={isOpen}>
      {basketState.services.map((service, i) => (
        <div key={i}>
          <BasketItem name={service.name} count={i} image={service.icon} />
        </div>
      ))}
      <StyledBookButton
        onClick={() => {
          handleClickCheckoutButton(!isOpen);
          history.push(APP_ROUTES.BOOKING);
        }}
      >
        Check out
      </StyledBookButton>
    </Container>
  );
};

interface ContainerProps {
  open: boolean;
}
const Container = styled.div<ContainerProps>`
  display: ${p => (p.open ? "" : "none")};
  position: absolute;
  min-width: 288px;
  max-width: 400px;
  border: 0.5px solid #b9b9b9;
  border-bottom: none;
  right: 0;
  background: #ffffff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.16);
  @media (max-width: 767px) {
    display: none;
  }
`;

const StyledBookButton = styled(BookButton)`
  margin: 0;
  width: 100%;
  border: none;
`;
