import React, { useState, useContext } from "react";
import styled from "styled-components/macro";
import { menuData } from "../menuData";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faBars } from "@fortawesome/free-solid-svg-icons";
import ecoLogo from "../../../assets/images/eco-transportation-logo.svg";
import { Drawer, Badge } from "antd";
import { APP_ROUTES } from "../../../config/router.constants";
import { BasketContext } from "../../../config/basketContext";

const MobileMenu: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const basketState = useContext(BasketContext);
    return (
        <MobileMenuContainer>
            <Link to="/">
                <Logo src={ecoLogo} />
            </Link>
            <div onClick={() => setVisible(!visible)}>
                <Icon icon={faBars} style={{ fontSize: "3rem" }} />
            </div>

            {visible && (
                <StyledDrawer
                    placement="right"
                    closable={true}
                    onClose={() => setVisible(!visible)}
                    visible={visible}
                >
                    <DrawerContainer>
                        <StyledLink
                            to={APP_ROUTES.HOME}
                            onClick={() => setVisible(!visible)}
                        >
                            Home
            </StyledLink>
                        {menuData.map((menuItem, i) => (
                            <StyledLink
                                key={i}
                                to={`/${menuItem}`}
                                onClick={() => setVisible(!visible)}
                            >
                                {menuItem}
                            </StyledLink>
                        ))}

                        <StyledLink
                            to={APP_ROUTES.HOME}
                            onClick={() => setVisible(!visible)}
                        >
                            <Badge
                                style={{ background: "#434343" }}
                                count={basketState.services.length}
                                offset={[0, 0]}
                                title="Items in basket"
                            >
                                <FontAwesomeIcon style={{ fontSize: "1.7rem" }} icon={faCar} />
                            </Badge>
                        </StyledLink>
                    </DrawerContainer>
                </StyledDrawer>
            )}
        </MobileMenuContainer>
    );
};

const MobileMenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  @media (min-width: 768px) {
    display: none;
  }
`;
const DrawerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 32px 0;
`;
const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 24px 0;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-style: normal;
  font-size: 18px;
  font-family: "Roboto";
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  text-transform: capitalize;
  text-decoration: none;
  &:hover {
    background-color: #00a76d;
    color: #ffffff;
  }
`;
const Logo = styled.img`
  display: flex;
  height: 45px;
  width: 88px;
  margin: 0 auto;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: 0 16px;
  font-size: 1.2rem;
  color: #504b4b;
  &:hover {
    cursor: pointer;
    color: #00a76d;
  }
  @media (max-width: 1024px) {
    margin: 0 8px;
  }
`;
export default MobileMenu;
