import React from "react";
import styled from "styled-components/macro";
import useSWR from "swr";
import ContentBlocks from "../../shared-components/content-blocks";
import Loader from "../../shared-components/loading/loading";
import { apiUrls, fetcher } from "../../utils/apiUrls";

export const ServicesContainer = () => {
    const { data, error } = useSWR(apiUrls.services, fetcher);

    if (error) return <div>failed to load</div>
    if (!data) return <Loader />

    const { acf } = data.data;

    return (
        <Container>
            <ContentBlocks contentBlocks={acf.contentBlocks} />
        </Container>
    );
};

const Container = styled.div``;