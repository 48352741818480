import React from "react";
import styled from "styled-components/macro";
import { WPImage } from '../../../interfaces';

type Props = {
    title: '',
    editor: '',
    image: WPImage
}

export default function Hero({ slides = [] }) {
    const { title, editor, image }: Props = slides[0];
    return (
        <TitleContainer bgimg={image}>
            <Content>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
                <TitleText dangerouslySetInnerHTML={{ __html: editor }} />
            </Content>
        </TitleContainer>
    )
}

const Content = styled.div``;
const Title = styled.h1`
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
  min-width: 300px;
  max-width: 409px;
  font-weight: bold;
  font-family: "Roboto";
  font-size: 40px;
  margin: 0;
  
  span{
    color: #00a76d;
  }
`;
const TitleText = styled.div`
  p{
    color: #ffffff;
    max-width: 382px;
    min-width: 280px;
    text-align: center;
    padding: 0px 32px;
    font-weight: normal;
    font-family: "Roboto";
  }
`;
const TitleContainer = styled.div<{ bgimg: WPImage }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${p => `url('${p.bgimg.url}') no-repeat center center;`};
  background-size: cover;
  height: 338px;
  width: 100%;
  @media(min-width: 1276px){
    background-size: cover;
  }
`;