import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { InputWithTitle, InputTitle } from "../shared/InputWithTitle";
import { ConfirmationInputWithTitle } from "./ConfirmationInputWithTitle";
import { months, years } from "../data/paymentData";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../config/router.constants";
import handleNextStep from "../../utils/handleNextStep";
import { ECO_TRANSPORT_LOCAL_STORAGE_CONSTANTS } from "../../../../config/localStorageConstants";
import {
  PaymentProps,
  initialPaymentState,
  PaymentErrors
} from "./initialState";
import { validator } from "../../../../utils/validator";
import { vehicleSelectionData } from "../../../fleet/fleetData";

export const Payment = () => {
  const [paymentData, setPaymentData] = useState<PaymentProps>(
    initialPaymentState
  );
  const [errors, setErrors] = useState<PaymentErrors>({});
  const history = useHistory();
  useEffect(() => {
    const storageOverviewData = JSON.parse(
      localStorage.getItem("overviewData")!
    );
    const storageVehicleData = JSON.parse(localStorage.getItem("vehicleData")!);
    const storageConfirmationData = JSON.parse(
      localStorage.getItem("confirmationData")!
    );
    if (
      !storageOverviewData ||
      !storageVehicleData ||
      !storageConfirmationData
    ) {
      return;
    } else {
      setPaymentData({
        ...storageOverviewData,
        ...storageVehicleData,
        ...storageConfirmationData,
        ccNumber: "",
        cvv: "",
        expMonth: "",
        expYear: ""
      });
    }
  }, []);
  const handleInputChange = (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const val = e.currentTarget.value;
    const key = e.currentTarget.name;
    setPaymentData({ ...paymentData, [key]: val });
  };

  return (
    <Container>
      <CreditCardContainer>
        <div style={{ gridArea: "cc" }}>
          <InputWithTitle
            title="Credit Card Number"
            placeholder="000 000 000 0000"
            handleChange={handleInputChange}
            isValid={errors.ccNumber}
            name="ccNumber"
          />
        </div>
        <InputWithTitle
          title="cvv"
          placeholder="123"
          handleChange={handleInputChange}
          isValid={errors.cvv}
          name="cvv"
        />
        <div>
          <InputTitle>Expiration month</InputTitle>
          <Select
            value={paymentData.expMonth}
            onChange={e => {
              handleInputChange(e);
            }}
            isValid={errors.expMonth}
            name="expMonth"
          >
            <Option value="">Month</Option>
            {months.map(month => (
              <Option key={month} value={month}>
                {month}
              </Option>
            ))}
          </Select>
        </div>
        <div>
          <InputTitle>expiration year</InputTitle>
          <Select
            value={paymentData.expYear}
            onChange={e => {
              handleInputChange(e);
            }}
            name="expYear"
            isValid={errors.expYear}
          >
            <Option value="">Year</Option>
            {years.map(year => (
              <Option key={year} value={year}>
                {year}
              </Option>
            ))}
          </Select>
        </div>
      </CreditCardContainer>
      <ConfirmationTitle>overview of purchase</ConfirmationTitle>
      <ConfirmationContainer>
        <ConfirmationInputWithTitle
          title="service type"
          value={paymentData.serviceType || ""}
          position="service"
        />
        <ConfirmationInputWithTitle
          title="date"
          value={paymentData.pickUpDate || ""}
          position="date"
        />
        <ConfirmationInputWithTitle
          title="time"
          value={paymentData.pickUpTime || ""}
          position="time"
        />
        <ConfirmationInputWithTitle
          title="pickup location"
          value={paymentData.pickUpLocation || ""}
          position="pickup"
        />
        <ConfirmationInputWithTitle
          title="drop off location"
          value={paymentData.dropOffLocation || ""}
          position="dropoff"
        />
        <ConfirmationInputWithTitle
          title="number of passengers"
          value={paymentData.passengers || 0}
          position="passengers"
        />
        <ConfirmationInputWithTitle
          title="luggage count"
          value={paymentData.luggage || 0}
          position="luggage"
        />
        <ConfirmationInputWithTitle
          title="child seat"
          value="none"
          position="child"
        />
        <ConfirmationInputWithTitle
          title="handicap access"
          value={paymentData.handicapAccess === "0" ? "No" : "Yes"}
          position="handicap"
        />
        <ConfirmationInputWithTitle
          title="vehicle"
          //TODO: fix me
          value={
            (paymentData.vehicle &&
              (vehicleSelectionData as any)[paymentData.vehicle].name) ||
            ""
          }
          position="vehicle"
        />
        <ConfirmationInputWithTitle
          title="first name"
          value={paymentData.firstName}
        />
        <ConfirmationInputWithTitle
          title="last name"
          value={paymentData.lastName}
          position="lastname"
        />
        <ConfirmationInputWithTitle title="phone" value={paymentData.phone} />
        <ConfirmationInputWithTitle title="email" value={paymentData.email} />
        <ConfirmationInputWithTitle
          title="company"
          value={paymentData.company}
        />
        <ConfirmationInputWithTitle
          title="country"
          value={paymentData.country}
        />
        <ConfirmationInputWithTitle
          title="city"
          value={paymentData.city}
          position="city"
        />
        <ConfirmationInputWithTitle title="state" value={paymentData.state} />
        <ConfirmationInputWithTitle
          title="postal / zip code"
          value={paymentData.zip}
          position="zip"
        />
        <ConfirmationInputWithTitle
          title="credit card number"
          value={paymentData.ccNumber}
        />
        <ConfirmationInputWithTitle
          title="CVV"
          value={paymentData.cvv}
          position="cvv"
        />
        <ConfirmationInputWithTitle
          title="expiration month"
          value={paymentData.expMonth}
        />
        <ConfirmationInputWithTitle
          title="expiration year"
          value={paymentData.expYear}
          position="expirationyear"
        />
        <PriceContainer position="price" background="#016c6d">
          <PriceText>company</PriceText>
          <PriceText>$ xxxx</PriceText>
          <PriceText>government tax</PriceText>
          <PriceText>$ xxxx</PriceText>
          <PriceText>PFS, Booking & Authorization Fees</PriceText>
          <PriceText>$ xxxx</PriceText>
        </PriceContainer>
        <PriceContainer position="total" background="#00A76D">
          <PriceText>total</PriceText>
          <PriceText>$ xxxx</PriceText>
        </PriceContainer>
      </ConfirmationContainer>
      <BookButton
        onClick={() => {
          const errors = validator(paymentData);
          if (Object.keys(errors).some(key => (errors as any)[key]) === true) {
            return setErrors(errors);
          }
          handleNextStep(
            ECO_TRANSPORT_LOCAL_STORAGE_CONSTANTS.ECO_TRANSPORT_BOOKING_PAYLOAD,
            paymentData,
            true
          );
          history.push(APP_ROUTES.HOME);
        }}
      >
        complete purchase
      </BookButton>
    </Container>
  );
};

const Container = styled.div`
  padding: 50px 50px 0 50px;
  @media (max-width: 480px) {
    padding: 50px 0;
  }
`;

const CreditCardContainer = styled.div`
  padding: 8px 24px;
  display: grid;
  gap: 42px 48px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas: "cc cc cc" "cvv expireMonth expireYear";
  @media (max-width: 840px) {
    grid-template-columns: 100%;
    grid-template-areas: "cc" "cvv" "expireMonth" "expireYear";
  }
`;
const Select = styled.select<{ isValid?: boolean }>`
  color: #b9b9b9;
  padding: 0px 24px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  border: ${({ isValid }) => (isValid ? "1px solid red" : "1px solid #e5e5e5")};
  height: 49px;
  width: 100%;
`;
// TODO: This this work?
const Option = styled.option`
  color: #b9b9b9;
  border: 1px solid #e5e5e5;
`;
const ConfirmationTitle = styled.h1`
  margin: 120px 0 24px 0;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto";
  color: #434343;
  text-transform: uppercase;
`;
const ConfirmationContainer = styled.div`
  border: 1px solid #e5e5e5;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(10, auto);
  grid-template-areas:
    "service date time"
    "pickup dropoff dropoff"
    "passengers luggage luggage"
    "child handicap handicap"
    "vehicle vehicle vehicle"
    "firstname lastname lastname"
    "phone email company"
    "country city city"
    "state zip zip"
    "ccnumber cvv cvv"
    "expirationday expirationyear expirationyear"
    "price price price"
    "total total total";
  @media (max-width: 840px) {
    grid-template-columns: repeat(1, 100%);
    grid-template-rows: repeat(10, auto);
    grid-template-areas:
      "service"
      "date"
      "time"
      "pickup"
      "dropoff"
      "passengers"
      "luggage"
      "child"
      "handicap"
      "vehicle"
      "firstname"
      "lastname"
      "phone"
      "email"
      "company"
      "country"
      "city"
      "state"
      "zip"
      "ccnumber"
      "cvv"
      "expirationday"
      "expirationyear"
      "price"
      "total";
  }
`;
interface PriceContainerProps {
  position: string;
  background: string;
}
const PriceContainer = styled.div<PriceContainerProps>`
  grid-area: ${p => p.position};
  display: grid;
  column-gap: 80px;
  grid-template-columns: repeat(2, 140px);
  grid-template-rows: repeat(3, auto);
  justify-content: end;
  background: ${p => p.background};
  padding: 8px 0;
  @media (max-width: 492px) {
    padding: 8px 24px;
    justify-content: left;
  }
`;

const PriceText = styled.p`
  font-weight: bold;
  font-size: 16px;
  font-family: "Roboto";
  text-transform: capitalize;
  color: #ffffff;
  margin: 0;
  padding: 8px 0;
`;

export const BookButton = styled.button`
  border: none;
  width: 228px;
  height: 48px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00a76d;
  color: #ffffff;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  margin: 88px auto;
  cursor: pointer;
  :hover {
    color: #ffffff;
  }
  :disabled {
    opacity: 0.6;
  }
`;
