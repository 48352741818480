import React, { useState } from "react";
import styled from "styled-components/macro";
import { FleetItem } from "./components/FleetItem";
import { Row, Col } from "antd";
import { FlippedCard } from "./components/FlippedCard";

export default function Fleet({ fleet = [] }) {
    const [activeCard, setActiveCard] = useState<number>();
    const handleClick = (i: number) => {
        i !== activeCard ? setActiveCard(i) : setActiveCard(undefined);
    };
    return (
        <FleetContainer>
            <Row gutter={[18, 12]}>
                {fleet?.length > 0 && fleet.map(({ post_title, post_type, post_name, acf }, i) => (
                    <Col
                        key={i}
                        id={i.toString()}
                        xs={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 8 }}
                        xl={{ span: 6 }}
                        onClick={() => handleClick(i)}
                    >
                        {
                            activeCard === i ?
                                <FlippedCard {...acf} title={post_title} type={post_type} slug={post_name}/>
                                :
                                <FleetItem {...acf} title={post_title} />
                        }
                    </Col>
                ))}
            </Row>
        </FleetContainer>
    );
};

const FleetContainer = styled.div`
  margin-top: 32px;
`;
