import { ContactErrors } from "../pages/contact/utils/utils";
import { OverviewErrors } from "../pages/booking/components/Overview/initialState";
import { ConfirmationErrors } from "../pages/booking/components/confirmation/initialState";
import { PaymentErrors } from "../pages/booking/components/payment/initialState";

enum ValidatorEnum {
  // Contact
  FULL_NAME = "fullName",
  EMAIL = "email",
  CITY = "city",
  PHONE_NUMBER = "phone",
  MESSAGE = "message",
  // Overview
  SERVICE_TYPE = "serviceType",
  PICK_UP_DATE = "pickUpDate",
  PICK_UP_TIME = "pickUpTime",
  PICK_UP_LOCATION = "pickUpLocation",
  DROP_OFF_LOCATION = "dropOffLocation",
  // Confirmation
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  COMPANY = "company",
  COUNTRY = "country",
  STATE = "state",
  ZIP = "zip",
  ADDRESS_ONE = "address1",
  ADDRESS_TWO = "address2",
  // Payment
  CREDIT_CARD_NUMBER = "ccNumber",
  CVV = "cvv",
  EXPIRATION_MONTH = "expMonth",
  EXPIRATION_YEAR = "expYear"
}
interface ErrorInterface
  extends ContactErrors,
    OverviewErrors,
    ConfirmationErrors,
    PaymentErrors {}
export const validator = (objectToValidate: any) => {
  let errors: ErrorInterface = {};
  Object.keys(objectToValidate).forEach(objectKey => {
    const value = objectToValidate[objectKey];

    switch (objectKey) {
      case ValidatorEnum.FULL_NAME:
        errors = {
          ...errors,
          fullName: isEmpty(value) || !isMinLength(value, 3)
        };
        break;
      case ValidatorEnum.FIRST_NAME:
        errors = {
          ...errors,
          firstName: isEmpty(value) || !isMinLength(value, 3)
        };
        break;
      case ValidatorEnum.LAST_NAME:
        errors = {
          ...errors,
          lastName: isEmpty(value) || !isMinLength(value, 3)
        };
        break;
      case ValidatorEnum.EMAIL:
        errors = { ...errors, email: isEmpty(value) || !isEmail(value) };
        break;
      case ValidatorEnum.CITY:
        errors = { ...errors, city: isEmpty(value) || !isMinLength(value, 3) };
        break;
      case ValidatorEnum.PHONE_NUMBER:
        errors = { ...errors, phone: isEmpty(value) || !isLength(value, 7) };
        break;
      case ValidatorEnum.MESSAGE:
        errors = {
          ...errors,
          message: isEmpty(value) || !isMinLength(value, 20)
        };
        break;
      case ValidatorEnum.SERVICE_TYPE:
        errors = {
          ...errors,
          serviceType: isEmpty(value)
        };
        break;
      case ValidatorEnum.PICK_UP_DATE:
        errors = {
          ...errors,
          pickUpDate: isEmpty(value)
        };
        break;
      case ValidatorEnum.PICK_UP_TIME:
        errors = {
          ...errors,
          pickUpTime: isEmpty(value)
        };
        break;
      case ValidatorEnum.PICK_UP_LOCATION:
        errors = {
          ...errors,
          pickUpLocation: isEmpty(value)
        };
        break;
      case ValidatorEnum.DROP_OFF_LOCATION:
        errors = {
          ...errors,
          dropOffLocation: isEmpty(value)
        };
        break;
      case ValidatorEnum.COMPANY:
        errors = {
          ...errors,
          company: isEmpty(value)
        };
        break;
      case ValidatorEnum.COUNTRY:
        errors = {
          ...errors,
          country: isEmpty(value)
        };
        break;
      case ValidatorEnum.STATE:
        errors = {
          ...errors,
          state: isEmpty(value)
        };
        break;
      case ValidatorEnum.ZIP:
        errors = {
          ...errors,
          zip: isEmpty(value) || !isLength(value, 5)
        };
        break;
      case ValidatorEnum.ADDRESS_ONE:
        errors = {
          ...errors,
          address1: isEmpty(value)
        };
        break;
      case ValidatorEnum.ADDRESS_TWO:
        errors = {
          ...errors,
          address2: isEmpty(value)
        };
        break;
      case ValidatorEnum.CREDIT_CARD_NUMBER:
        errors = {
          ...errors,
          ccNumber: isEmpty(value) || !isCreditCard(value)
        };
        break;
      case ValidatorEnum.CVV:
        errors = {
          ...errors,
          cvv: isEmpty(value) || !isLength(value, 3)
        };
        break;
      case ValidatorEnum.EXPIRATION_MONTH:
        errors = {
          ...errors,
          expMonth: isEmpty(value)
        };
        break;
      case ValidatorEnum.EXPIRATION_YEAR:
        errors = {
          ...errors,
          expYear: isEmpty(value)
        };
        break;
      default:
        break;
    }
  });
  return errors;
};

export const isCreditCard = (number: string) => {
  number.trim();
  const numLen = number.length;
  return numLen >= 14 && numLen <= 16;
};
export const isNotEmpty = (str: string) => {
  str.trim();
  return str.length > 0;
};
export const isEmpty = (str: string) => {
  str.trim();
  return str.length === 0;
};
export const isMinLength = (str: string, minLength: number) => {
  str.trim();
  return str.length >= minLength;
};
export const isLength = (str: string, length: number) => {
  str.trim();
  return str.length === length;
};
export const isEmail = (email: string) => {
  email.trim();
  const regex = new RegExp(
    "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
  );
  return regex.test(email);
};
