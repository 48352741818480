import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import { APP_ROUTES } from "./router.constants";
import { Router } from "react-router-dom";
import HomeContainer from "../pages/home/HomeContainer";
import AboutContainer from "../pages/about/AboutContainer";
import Navigation from "../shared-components/navigation/Navigation";
import { Footer } from "../shared-components/Footer/Footer";
import { ServicesContainer } from "../pages/ecoServices/ServicesContainer";
import { ContactContainer } from "../pages/contact/ContactContainer";
import { Fleet } from "../pages/fleet/Fleet";
import { FleetSingleContainer } from "../pages/fleetSingle/FleetSingleContainer";
import { createBrowserHistory } from "history";
import { BasketContext } from "./basketContext";
import { ServiceTypes } from "../shared-components/content-blocks/TypesOfService/typesOfServiceData";

export const history = createBrowserHistory();

export const AppRouter: React.FC = () => {
    const [services, setServices] = useState<ServiceTypes[]>([]);
    return (
        <div>
            <Router history={history}>
                <BasketContext.Provider value={{ services: services, setServices }}>
                    <Navigation />
                    <Switch>
                        <Route exact path={APP_ROUTES.HOME} component={HomeContainer} />
                        <Route path={APP_ROUTES.SERVICES} component={ServicesContainer} />
                        <Route path={APP_ROUTES.ABOUT} component={AboutContainer} />
                        <Route path={APP_ROUTES.CONTACT} component={ContactContainer} />
                        <Route exact path={APP_ROUTES.FLEET} component={Fleet} />
                        <Route
                            path={`${APP_ROUTES.FLEET}/:slug`}
                            component={FleetSingleContainer}
                        />
                    </Switch>
                </BasketContext.Provider>
                <Footer />
            </Router>
        </div>
    );
};
export default AppRouter;
