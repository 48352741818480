import React from "react";
import styled from "styled-components";
import fleetImage from "../../../assets/images/Home Page/Grupo 458.png";

export default function OurFleet(){
  return <Container />;
};

const Container = styled.div`
  height: 587px;
  background: url('${fleetImage}') no-repeat center;
  background-size: contain;
  margin: 0 0 130px 0;
  @media (max-width: 600px) {
    height: 300px;
    margin: 0 0 0px 0;
  }
`;
