import React from "react";
import styled from "styled-components/macro";
import heroImage from "../../../../assets/images/Fleets/Enmascarar grupo 8.png";
export const FleetHero = () => {
  return (
    <Container>
      <Content>
        <Title>
          our <TitleGreen>fleet</TitleGreen>
        </Title>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed ex
          urna. Pellentesque at
        </Text>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  background: url('${heroImage}') no-repeat center center;
  background-size: cover;
  height: 338px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  max-width: 370px;
  padding: 16px;
`;
const Title = styled.div`
  text-transform: uppercase;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 60px;
  line-height: 48px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 8px;
`;
const TitleGreen = styled.span`
  color: #00a76d;
`;
const Text = styled.div`
  font-family: "Roboto";
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
`;
