import React from "react";

import styled from "styled-components/macro";

export const Checkbox = () => {
  return (
    <Container>
      <CheckboxContainer>
        <CheckBox type="checkbox" />
        <CheckboxText>max 6</CheckboxText>
      </CheckboxContainer>
      <CheckboxContainer>
        <CheckBox type="checkbox" />
        <CheckboxText>kids</CheckboxText>
      </CheckboxContainer>
      <CheckboxContainer>
        <CheckBox type="checkbox" />
        <CheckboxText>wheelchair</CheckboxText>
      </CheckboxContainer>
      <CheckboxContainer>
        <CheckBox type="checkbox" />
        <CheckboxText>item x</CheckboxText>
      </CheckboxContainer>
    </Container>
  );
};
const Container = styled.div`
  border-top: 0.5px solid #b9b9b9;
  display: flex;
  justify-content: space-around;
  width: fit-content;
  margin: 0 auto;
  padding-top: 8px;
`;
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 10px;
  margin: 0 8px;
  height: 20px;
`;
const CheckBox = styled.input`
  width: 10px;
  color: #e5e5e5;
`;
const CheckboxText = styled.span`
  color: #959393;
  font-family: Roboto;
  margin-left: 4px;
  text-transform: capitalize;
`;
