import React, { useState, useContext } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar } from "@fortawesome/free-solid-svg-icons";

import ecoLogo from "../../assets/images/eco-transportation-logo.svg";
import { menuData } from "./menuData";
import MobileMenu from "./mobileMenu/MobileMenu";
import { Row, Col, Badge } from "antd";
import { APP_ROUTES } from "../../config/router.constants";
import { Basket } from "../basket/Basket";
import { BasketContext } from "../../config/basketContext";

const Navigation: React.FC = () => {
    const [isBasketOpen, setIsBasketOpen] = useState(false);
    const { services } = useContext(BasketContext);
    return (
        <Container>
            <Row align="middle" justify="center">
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24, order: 2 }}
                    md={{ span: 0, order: 2 }}
                    lg={{ span: 0, order: 0 }}
                    xxl={{ span: 0, offset: 1 }}
                >
                    <MobileMenu />
                </Col>

                <Col
                    xs={{ span: 0, order: 2 }}
                    sm={{ span: 0, order: 2 }}
                    md={{ span: 0, order: 2 }}
                    lg={{ span: 3, order: 0 }}
                    xxl={{ span: 2, offset: 1 }}
                >
                    <Link to={APP_ROUTES.HOME}>
                        <Logo src={ecoLogo} alt="eco transportation" />
                    </Link>
                </Col>
                <Col
                    xs={{ span: 0, offset: 0 }}
                    sm={{ span: 0, offset: 0 }}
                    md={{ span: 21, offset: 0 }}
                    lg={{ span: 16, offset: 2 }}
                    xxl={{ span: 16, offset: 3 }}
                >
                    <MenuContainer>
                        {menuData.map(menuItem => (
                            <StyledLink key={menuItem} to={`/${menuItem}`}>
                                {menuItem}
                            </StyledLink>
                        ))}
                    </MenuContainer>
                </Col>
            </Row>
            <Basket
                isOpen={isBasketOpen}
                handleClickCheckoutButton={setIsBasketOpen}
            />
        </Container>
    );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 1;
  border-bottom: 0.5px solid rgba(228, 228, 228, 0.5);
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const MenuItemContainer = styled.div`
  height: 74px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: #00a76d;
    > a,
    svg {
      color: #ffffff;
    }
  }
`;
const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;
  width: 100%;
  font-size: 22px;
  font-family: Roboto;
  font-weight: normal;
  color: #504b4b;
  text-transform: capitalize;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background: #00a76d;
    color: #ffffff;
    > svg {
      color: #ffffff;
    }
  }
  @media (max-width: 1248px) {
    font-size: 18px;
  }
`;
const Logo = styled.img`
  display: flex;
  height: 45px;
  width: 88px;
  margin: 0 auto;
`;

const Icon = styled(FontAwesomeIcon)`
  margin: 0 16px;
  font-size: 1.2rem;
  color: #504b4b;
  &:hover {
    cursor: pointer;
    color: #1890ff;
  }
  @media (max-width: 1024px) {
    margin: 0 8px;
  }
`;

export default Navigation;
