export interface ContactInterface {
  fullName?: string;
  email?: string;
  city?: string;
  phone?: string;
  message?: string;
}
export interface ContactErrors {
  fullName?: boolean;
  email?: boolean;
  city?: boolean;
  phone?: boolean;
  message?: boolean;
}
export const initialContactState = {
  fullName: "",
  email: "",
  city: "",
  phone: "",
  message: ""
};
