import React from "react";
import styled from "styled-components/macro";

export const BottomBar = () => {
  return (
    <Container>
      <BottomBarText>© COPYRIGHT 2018 ECO DMS.</BottomBarText>
      <BottomBarText>BY CR38TE.</BottomBarText>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 65px;
  background: #002c3a;
  margin-top: 80px;
`;

const BottomBarText = styled.p`
  color: #ffffff;
  font-weight: normal;
  font-size: 13px;
  font-family: "Roboto";
  text-align: center;
  margin: 0;
`;
