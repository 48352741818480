import React from "react";
import { InputTitle, InputField } from "./InputWithTitle";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

interface CounterProps {
  title: string;
  type: string;
  handleCounterClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  count: number;
}

export const Counter: React.FC<CounterProps> = ({
  title,
  type,
  handleCounterClick,
  count
}) => {
  return (
    <div>
      <InputTitle>{title}</InputTitle>
      <CounterContainer>
        <CounterButton id={type} name="decrement" onClick={handleCounterClick}>
          <FontAwesomeIcon icon={faMinus} />
        </CounterButton>

        {type === "passengers" && (
          <StyledInputField
            type="text"
            readOnly
            placeholder="&#xf007;"
            value={count === 0 ? "" : count}
          />
        )}
        {type === "luggage" && (
          <StyledInputField
            type="text"
            readOnly
            placeholder="&#xf59d;"
            value={count === 0 ? "" : count}
          />
        )}

        <CounterButton id={type} name="increment" onClick={handleCounterClick}>
          <FontAwesomeIcon icon={faPlus} />
        </CounterButton>
      </CounterContainer>
    </div>
  );
};

const CounterContainer = styled.div`
  display: flex;
`;

const StyledInputField = styled(InputField)`
  padding: 0;
  text-align: center;
  font-family: "Font Awesome 5 Free";
`;
const CounterButton = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 49px;
  color: #b9b9b9;
  padding: 0px 24px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  border: 1px solid #e5e5e5;
  :hover {
    cursor: pointer;
  }
`;
