import React from "react";
import styled from "styled-components/macro";
import { ServiceItem } from "./ServiceItem";
import { Row, Col } from "antd";

export default function TypesOfService({ services = [] }) {
    if (services?.length === 0) return null;
    return (
        <Container>
            <Row justify="center" gutter={[16, 16]}>
                {services.map(({ acf }, i) => (
                    <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 10 }}
                        xl={{ span: 6 }}
                        key={i}
                    >
                        <ServiceItem {...acf} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

const Container = styled.div`
  margin: 74px 0;
  @media (max-width: 1024px) {
    margin: 74px 0 90px 0;
  }
  @media (max-width: 768px) {
    margin: 74px 0 50px 0;
  }
  @media (max-width: 600px) {
    margin: 74px 0 40px 0;
  }
`;
