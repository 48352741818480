import React from "react";
import styled from "styled-components/macro";
import useSWR from "swr";
import { apiUrls, fetcher } from "../../utils/apiUrls";
import Loader from "../../shared-components/loading/loading";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { ContactInterface } from "./utils/utils";
import { ContactForm } from './ContactForm';

export const ContactContainer: React.FC<ContactInterface> = () => {
    const { data, error } = useSWR(apiUrls.contact, fetcher);

    if (error) return <div>failed to load</div>
    if (!data) return <Loader />

    const { post_title, acf } = data.data;
    return (
        <Container>
            <Row>
                <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 20, offset: 2 }}
                    xxl={{ span: 6, offset: 6 }}
                >
                    <Title>{post_title}</Title>
                </Col>
            </Row>
            <ContactForm />
            <MapsContainer>
                <MapTitle>
                    <Icon icon={faMapMarkerAlt} /> {acf.address}
                </MapTitle>
                <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3894.7723219292325!2d-70.05304508473226!3d12.531236627777211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e85477d778d704d%3A0x18501736492d3183!2sItaliestraat%2016%2C%20Oranjestad%2C%20Aruba!5e0!3m2!1sen!2snl!4v1573975583642!5m2!1sen!2snl"></Iframe>
            </MapsContainer>
        </Container>
    );
};
const Icon = styled(FontAwesomeIcon)`
  font-size: 1.2rem;
  margin: 0 8px;
`;
const MapsContainer = styled.div`
  margin-top: 173px;
`;
const MapTitle = styled.h1`
  height: 53px;
  background: #00a76d;
  color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  @media (max-width: 424px) {
    display: none;
  }
`;
const Iframe = styled.iframe`
  width: 100%;
  height: 600px;
  border: none;
`;
const Container = styled.div`
  padding: 0 0;
`;
const Title = styled.h1`
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 40px;
  margin: 105px 0 20px 0;
  color: #002c3a;
  @media (max-width: 766px) {
    text-align: center;
  }
`;