import React from "react";
import styled from "styled-components/macro";
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Contact = () => {
  return (
    <Container>
      <ContactItem>
        <StyledIcon icon={faPhoneAlt} />
        <ContactText>+1 954 693 6362</ContactText>
      </ContactItem>
      <ContactItem>
        <StyledIcon icon={faEnvelope} />
        <ContactText>Info@Ecotransportation.com</ContactText>
      </ContactItem>
      <ContactItem>
        <StyledIcon icon={faMapMarkerAlt} />
        <ContactText>Italiestraat 16, Oranjestad, Aruba</ContactText>
      </ContactItem>
    </Container>
  );
};

const Container = styled.div`
  width: fit-content;
  margin: 0 auto;
`;

const ContactItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: #00a76d;
`;

const ContactText = styled.p`
  color: #3a3a3a;
  font-weight: normal;
  font-size: 16px;
  font-family: "Roboto";
  margin: 0px 0px 0 16px;
`;
