export const initialOverviewState = {
  serviceType: "",
  pickUpDate: "",
  pickUpTime: "",
  pickUpLocation: "",
  dropOffLocation: "",
  handicapAccess: "0",
  luggage: 0,
  passengers: 0
};

export interface OverviewProps {
  serviceType: string;
  pickUpDate: string;
  pickUpTime: string;
  pickUpLocation: string;
  dropOffLocation: string;
  handicapAccess: string;
  luggage: number;
  passengers: number;
}

export interface OverviewErrors {
  serviceType?: boolean;
  pickUpDate?: boolean;
  pickUpTime?: boolean;
  pickUpLocation?: boolean;
  dropOffLocation?: boolean;
}
