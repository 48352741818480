import confetti from "../../../assets/images/Home Page/confeti.png";
import police from "../../../assets/images/Home Page/police.png";
import ring from "../../../assets/images/Home Page/ring.png";
import bubble from "../../../assets/images/Home Page/bubble.png";

export const typesOfServiceData = [
  {
    name: "Type of service",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: confetti,
    price: "$250"
  },
  {
    name: "Type of service",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: police,
    price: "$250"
  },
  {
    name: "Type of service",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: ring,
    price: "$250"
  },
  {
    name: "Type of service",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: bubble,
    price: "$250"
  }
];
export interface ServiceTypes {
  name: string;
  text: string;
  icon: string;
  price: string;
  id: string;
}
export const serviceTypes: ServiceTypes[] = [
  {
    id: "0",
    name: "Type of service1",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: confetti,
    price: "$250"
  },
  {
    id: "1",
    name: "Type of service2",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: police,
    price: "$250"
  },
  {
    id: "2",
    name: "Type of service3",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: ring,
    price: "$250"
  },
  {
    id: "3",
    name: "Type of service4",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: bubble,
    price: "$250"
  },
  {
    id: "4",
    name: "Type of service5",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: ring,
    price: "$250"
  },
  {
    id: "5",
    name: "Type of service6",
    text:
      "Lorem ipsum dolor sit amet, i consectetur isone adipiscing elit. Sed sed ex urna iosm.",
    icon: bubble,
    price: "$250"
  }
];
