import React from "react";
import styled from "styled-components/macro";

interface InputWithTitleProps {
  title: string;
  placeholder: string;
  name?: string;
  value?: string;
  isValid?: boolean;
  handleChange: (e: any) => void;
  handleValidation?: (e: any) => void;
}

export const InputWithTitle: React.FC<InputWithTitleProps> = ({
  title,
  placeholder,
  handleChange,
  handleValidation,
  isValid,
  name,
  value
}) => {
  return (
    <div>
      <InputTitle>{title}</InputTitle>
      <InputField
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={e => handleChange(e)}
        onBlur={e => handleValidation && handleValidation!(e)}
        isValid={isValid}
      />
    </div>
  );
};

export const InputField = styled.input<{ isValid?: boolean }>`
  color: #b9b9b9;
  padding: 0px 0 0 24px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  border: ${p => (p.isValid ? "1px solid red" : "1px solid #e5e5e5")};
  height: 49px;
  width: 100%;
  text-transform: capitalize;
`;
export const InputTitle = styled.h1`
  text-transform: capitalize;
  font-weight: bold;
  font-family: "Roboto";
  font-size: 17px;
  line-height: 17px;
  margin: 8px 0;
`;
