import React from "react";
import styled from "styled-components/macro";
import { FleetHero } from "../../shared-components/content-blocks/Fleet/components/FleetHero";
import useSWR from "swr";
import { apiUrls, fetcher } from "../../utils/apiUrls";
import Loader from "../../shared-components/loading/loading";
import ContentBlocks from "../../shared-components/content-blocks";

export const Fleet = () => {
    const { data, error } = useSWR(apiUrls.fleet, fetcher);

    if (error) return <div>failed to load</div>
    if (!data) return <Loader />

    const { acf } = data.data;

    return (
        <>
            <FleetHero />
            <Container>
                <ContentBlocks contentBlocks={acf.contentBlocks} />
            </Container>
        </>
    );
};

const Container = styled.div`
  padding: 0 32px;
`;
