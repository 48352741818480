export interface ConfirmationProps {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  company: string;
  country: string;
  city: string;
  state: string;
  zip: string;
  address1: string;
  address2: string;
}
export const initialConfirmationState = {
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  company: "",
  country: "",
  city: "",
  state: "",
  zip: "",
  address1: "",
  address2: ""
};

export interface ConfirmationErrors {
  firstName?: boolean;
  lastName?: boolean;
  phone?: boolean;
  email?: boolean;
  company?: boolean;
  country?: boolean;
  city?: boolean;
  state?: boolean;
  zip?: boolean;
  address1?: boolean;
  address2?: boolean;
}
