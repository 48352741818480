import React from "react";
import styled from "styled-components/macro";
import { Col, Row } from "antd";

const ContentBlockTemplate = ({ acf_fc_layout, ...props }) => {
    console.log(props);
    switch (acf_fc_layout) {
        case 'image': return <Image bgImage={props.image} />
        case 'video': return <Video /* props.video.url */ />
        case 'editor': return <TitleAndTextContainer dangerouslySetInnerHTML={{ __html: props.editor }} />
        default: return null;
    }
}

export default function DualContent({ leftContentBlocks, rightContentBlocks, }) {
    return (
        <Row justify="center" align="middle">
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10 }}>
                {
                    leftContentBlocks?.length > 0 &&
                    leftContentBlocks.map((contentBlock, i) =>
                        <ContentBlockTemplate
                            key={`left-content-block-template-${i}`}
                            {...contentBlock}
                        />
                    )
                }
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 10, offset: 1 }}>
                {
                    rightContentBlocks?.length > 0 &&
                    rightContentBlocks.map((contentBlock, i) =>
                        <ContentBlockTemplate
                            key={`left-content-block-template-${i}`}
                            {...contentBlock}
                        />
                    )
                }
            </Col>
        </Row>
    )
}

const Video = styled.div``;

const TitleAndTextContainer = styled.div`
  p{
      color: #676666;
      font-weight: normal;
      font-family: "Roboto";
      max-width: 495px;
      font-size: 16px;
      @media (max-width: 1023px) {
        text-align: center;
        padding: 0 16px;
        margin: 32px auto;
      }
  }

  h1{
    color: #002c3a;
    font-size: 2.5em;
    font-family: "Roboto";
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  @media (max-width: 1023px) {
    text-align: center;
    padding: 0 16px;
  }
`;

const Image = styled.div`
  height: 521px;
  background: ${p => `url('${p.bgImage.url}') no-repeat center center;`};
  background-size: cover;
  max-height: 390px;
  max-width: 521px;
  margin: 0 auto;
  @media(max-width:425px){
    height:250px;
  }
`;