import React from "react";
import styled from "styled-components";
import { EcoButton } from "../../../shared-components/button/Button";
import { Link } from "react-router-dom";

export default function Welcome({ addButton = Boolean, buttonLink = "", buttonText = "", description = "", title = "" }) {
    return (
        <Container>
            <Title dangerouslySetInnerHTML={{ __html: title }} />
            <Text dangerouslySetInnerHTML={{ __html: description }} />
            {
                addButton &&
                <Link to={buttonLink}>
                    <EcoButton text={buttonText} />
                </Link>
            }
        </Container>
    );
};

const Container = styled.div`
  margin-top: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 32px;
  font-family: Roboto;
  text-align: center;
  @media (min-width: 700px) {
    font-size: 40px;
  }
  span {
    color: #959393;
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 16px;
  text-align: center;
  padding: 8px 12px;

  @media (min-width: 700px) {
    width: 692px;
    margin: 0 0 76px 0;
    font-size: 17px;
  }
`;
