import React from "react";
import styled from "styled-components/macro";
import { Circle } from "../../../shared-components/circle/Circle";

export default function Testimonials({ backgroundImage, testimonials }) {
    return (
        <TestimonialContainer bgImage={backgroundImage}>
            {testimonials?.length > 0 && testimonials.map(({ name, quote, title }, i) =>
                <TestimonialContent key={`testimonial-${i}`}>
                    <TestimonialTitle>{title}</TestimonialTitle>
                    <TestimonialText dangerouslySetInnerHTML={{ __html: quote }} />
                    <Author>- {name}</Author>
                </TestimonialContent>
            )}
            <CirclesContainer>
                {testimonials?.length > 1 && testimonials.map((t, i) => <Circle key={`testimonial-nav-${t.title}-${i}`} />)}
            </CirclesContainer>
        </TestimonialContainer>
    )
}

const TestimonialContainer = styled.div`
  margin: 80px 0;
  background: ${p => `url('${p.bgImage.url}') no-repeat center center;`};
  background-size: cover;
`;
const TestimonialContent = styled.div`
  height: 340px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const TestimonialTitle = styled.h1`
  margin: 0;
  font-family: "Roboto";
  text-transform: uppercase;
  color: #ffffff;
  max-width: 284px;
  font-size: 2.8em;
  font-weight: bold;
`;
const TestimonialText = styled.p`
  font-family: "Roboto";
  color: #ffffff;
  max-width: 692px;
  min-width: 280px;
  padding: 24px;
  text-align: center;
  font-size: 15px;
  margin: 0;
  line-height: 24px;
`;

const Author = styled.p`
  font-family: "Roboto";
  color: #ffffff;
  max-width: 100px;
  text-transform: capitalize;
  font-weight: bold;
  margin: 16px 0 0 0;
`;

const CirclesContainer = styled.div`
  display: flex;
  margin-top: 40px;
  width: fit-content;
  margin: 0 auto;
  padding: 16px;
`;