import React, { useContext } from "react";
import styled from "styled-components/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { BasketContext } from "../../config/basketContext";
interface BasketItemProps {
  name: string;
  count: string | number;
  image: string;
}
export const BasketItem: React.FC<BasketItemProps> = ({
  name,
  image,
  count
}) => {
  const basketState = useContext(BasketContext);
  return (
    <Container>
      <Image image={image} />
      <ServiceCounterContainer>
        <Span>{name}</Span>

        <CounterItem>
          <StyledIcon icon={faMinus} />
        </CounterItem>
        <CounterItem>
          <Count>{count}</Count>
        </CounterItem>
        <CounterItem>
          <StyledIcon icon={faPlus} />
        </CounterItem>
      </ServiceCounterContainer>
      <TrashContainer
        onClick={() => {
          const updatedServices = basketState.services.filter(
            type => type.name !== name
          );
          basketState.setServices(updatedServices);
        }}
      >
        <TrashIcon icon={faTrash} />
      </TrashContainer>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 30px;
  grid-template-rows: 72px;
  border-bottom: 0.5px solid #b9b9b9;
`;
interface ImageProps {
  image: string;
}
const Image = styled.div<ImageProps>`
  width: 83px;
  height: 53px;
  background: url(${p => p.image}) no-repeat center center #e5e5e5;
  background-size: contain;
  align-self: center;
  justify-self: center;
`;
const ServiceCounterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-template-rows: 20px 30px;
  grid-template-areas: "name name name" "decrement count  increment";
  align-items: center;
  align-self: center;
`;
const CounterItem = styled.div``;
const Span = styled.span`
  min-width: 100px;
  grid-area: name;
  font-size: 10px;
  line-height: 18px;
  font-family: "Roboto";
  text-transform: capitalize;
  justify-self: start;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 0.6rem;
  justify-self: start;
  cursor: pointer;
`;
const Count = styled.span`
  height: 18px;
  width: 18px;
  background: #00a76d;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: start;
  grid-area: count;
`;

const TrashIcon = styled(FontAwesomeIcon)`
  margin-top: 16px;
`;

const TrashContainer = styled.div`
  border-left: 0.5px solid #b9b9b9;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
