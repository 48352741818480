import React from "react";
import styled from "styled-components/macro";

interface ButtonProps {
  text: string;
}
export const EcoButton: React.FC<ButtonProps> = ({ text }) => {
  return <Button>{text}</Button>;
};

const Button = styled.button`
  /* button styling */

  height: 44px;
  background: #00a76d;
  color: #ffffff;
  border: none;
  /* button text styling */
  font-size: 19px;
  font-family: Roboto;
  font-weight: normal;
  text-transform: uppercase;
  margin: 16px 0;
  padding: 8px 16px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &:hover {
    cursor: pointer;
  }
`;
