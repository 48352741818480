import React from "react";
import styled from "styled-components/macro";
import AppRouter from "./config/router";
import "antd/dist/antd.css";
import "./index.css";

const App: React.FC = () => {
  return (
    <AppContainer>
      <AppRouter />
    </AppContainer>
  );
};
const AppContainer = styled.div``;
export default App;
