import React from "react";
import styled from "styled-components/macro";
import { Checkbox } from "./Checkbox";
import { SingleFleetItem } from '../../../../interfaces';

export const FleetItem: React.FC<SingleFleetItem> = ({ title, cardHighlightImage }) => {
    return (
        <Container>
            <Content>
                {/* {checkbox && active ? <Check color="#00a76d" /> : checkbox ? <Check color="darkgrey" /> : null} */}
                <ImageContainer>
                    <Image src={cardHighlightImage.url} />
                </ImageContainer>
                <Title>{title}</Title>
                <Checkbox />
            </Content>
        </Container>
    );
};

const Container = styled.div`
  background: #e4e4e4;
  height: 377px;
  cursor: pointer;

  /* &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.75);
  } */
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
`;
const ImageContainer = styled.div`
  height: 250px;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  display: flex;
  margin: 0 auto;
`;
const Title = styled.h1`
  color: #002c3a;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  font-family: Roboto;
  margin: 0 0 16px 0;
  text-transform: uppercase;
`;

const Check = styled.div`
  position: relative;
  top: 16px;
  right: 16px;
  height: 20px;
  width: 20px;
  background: ${props => props.color};
  align-self: flex-end;
`;
