import React from "react";
import styled from "styled-components/macro";

export const Circle = () => {
  return <GreenCircle />;
};

const GreenCircle = styled.div`
  background: #00a76d;
  width: 12px;
  height: 12px;
  margin: 6px;
  border-radius: 50%;
  opacity: 0.5;
`;
