import React, { useState, ChangeEvent, useEffect } from "react";
import styled from "styled-components/macro";

import { InputWithTitle, InputTitle } from "../shared/InputWithTitle";
import { Counter } from "../shared/Counter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import "./range-slider.css";
import { useHistory } from "react-router-dom";
import { APP_ROUTES } from "../../../../config/router.constants";
import handleNextStep from "../../utils/handleNextStep";

import {
  initialOverviewState,
  OverviewProps,
  OverviewErrors
} from "./initialState";
import { validator } from "../../../../utils/validator";

export const Overview: React.FC<OverviewProps> = () => {
  const [errors, setErrors] = useState<OverviewErrors>({});
  const [overviewData, setOverviewData] = useState<OverviewProps>(
    initialOverviewState
  );
  const [handicapToggle, setHandicapToggle] = useState("0");
  const history = useHistory();
  useEffect(() => {
    if (!localStorage.getItem("overviewData")) {
      return;
    } else {
      const storageOverviewData = JSON.parse(
        localStorage.getItem("overviewData")!
      );
      setOverviewData(storageOverviewData);
    }
  }, []);

  const toggleHandicapSwitch = () => {
    if (handicapToggle === "0") {
      setHandicapToggle("1");
      setOverviewData({ ...overviewData, handicapAccess: "1" });
    } else {
      setHandicapToggle("0");
      setOverviewData({ ...overviewData, handicapAccess: "0" });
    }
  };
  const handleInputChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => {
    const val = e.currentTarget.value;
    const key = e.currentTarget.name;
    setOverviewData({ ...overviewData, [key]: val });
  };

  const handleCounterClick = (e: React.MouseEvent<HTMLDivElement>) => {
    const counterType = e.currentTarget!.id;
    const action = e.currentTarget.getAttribute("name");
    const currentValue = (overviewData as any)[counterType];
    if (currentValue === 0 && action === "decrement") {
      return;
    }
    if (action === "increment") {
      setOverviewData({
        ...overviewData,
        [counterType]: (overviewData as any)[counterType] += 1
      });
    } else {
      setOverviewData({
        ...overviewData,
        [counterType]: (overviewData as any)[counterType] -= 1
      });
    }
  };
  return (
    <>
      <Container>
        <ServiceContainer>
          <InputTitle>service type</InputTitle>
          <Select
            value={overviewData.serviceType}
            name="serviceType"
            onChange={e => {
              handleInputChange(e);
            }}
            isValid={errors.serviceType}
          >
            <Option value="">Transfer service type</Option>
            <Option value="Test">Test</Option>
          </Select>
        </ServiceContainer>
        <InputWithTitle
          title="pick-up date"
          placeholder="08/08/2018"
          handleChange={handleInputChange}
          name="pickUpDate"
          value={overviewData.pickUpDate || ""}
          isValid={errors.pickUpDate}
        />
        <InputWithTitle
          title="pick-up time"
          placeholder="08:38 PM"
          handleChange={handleInputChange}
          name="pickUpTime"
          value={overviewData.pickUpTime || ""}
          isValid={errors.pickUpTime}
        />
        <InputWithTitle
          title="pick-up location"
          placeholder="location here"
          handleChange={handleInputChange}
          name="pickUpLocation"
          value={overviewData.pickUpLocation || ""}
          isValid={errors.pickUpLocation}
        />
        <InputWithTitle
          title="drop-off location"
          placeholder="location here"
          handleChange={handleInputChange}
          name="dropOffLocation"
          value={overviewData.dropOffLocation || ""}
          isValid={errors.dropOffLocation}
        />
        <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3894.7723219292325!2d-70.05304508473226!3d12.531236627777211!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e85477d778d704d%3A0x18501736492d3183!2sItaliestraat%2016%2C%20Oranjestad%2C%20Aruba!5e0!3m2!1sen!2snl!4v1573975583642!5m2!1sen!2snl" />
        <Counter
          title="number of passengers"
          type="passengers"
          handleCounterClick={handleCounterClick}
          count={overviewData.passengers || 0}
        />
        <Counter
          title="luggage count"
          type="luggage"
          handleCounterClick={handleCounterClick}
          count={overviewData.luggage || 0}
        />
        <ChildSeatContainer>
          <Icon icon={faPlus} />
          <Span>add child seat</Span>
        </ChildSeatContainer>
        <SwitchContainer>
          <InputTitle>Handicap access</InputTitle>
          <RangeContainer active={overviewData.handicapAccess}>
            <input
              type="range"
              min="0"
              max="1"
              value={overviewData.handicapAccess || ""}
              onClick={toggleHandicapSwitch}
              name="handicapAccess"
              onChange={handleInputChange}
              className="active"
            />
          </RangeContainer>
        </SwitchContainer>
      </Container>
      <BookButton
        // disabled={
        //   Object.keys(errors).some(key => (errors as any)[key]) === true ||
        //   Object.keys(overviewData).some(
        //     key => (overviewData as any)[key].length === 0
        //   )
        // }
        onClick={async () => {
          const errors = await validator(overviewData);
          console.log(overviewData);
          console.log(errors);
          if (
            (await Object.keys(errors).some(key => (errors as any)[key])) ===
            true
          ) {
            setErrors(errors);
          } else {
            handleNextStep("overviewData", overviewData);
            history.push(APP_ROUTES.BOOKING_VEHICLE);
          }
        }}
      >
        next step
      </BookButton>
    </>
  );
};

const Container = styled.div`
  display: grid;
  padding: 48px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, auto);
  gap: 42px 48px;
  grid-template-areas:
    "service-type service-type"
    "date time"
    "pick-location map"
    "drop-location map"
    "passengers luggage"
    "child-seat handicap-access";
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(11, auto);
    row-gap: 40px;
    grid-template-areas:
      "service-type"
      "date" "time"
      "pick-location"
      "drop-location"
      "map"
      "map"
      "passengers" "luggage"
      "child-seat" "handicap-access";
  }
`;
const ServiceContainer = styled.div`
  grid-area: service-type;
`;

const Select = styled.select<{ isValid?: boolean }>`
  color: #b9b9b9;
  padding: 0px 24px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
  height: 49px;
  width: 100%;
  border: ${p => (p.isValid ? "1px solid red" : "1px solid #e5e5e5")};
`;
// TODO: This this work?
const Option = styled.option`
  color: #b9b9b9;
  border: 1px solid #e5e5e5;
`;
const Iframe = styled.iframe`
  grid-area: map;
  width: 100%;
  height: 100%;
  border: none;
  @media (max-width: 767px) {
    height: 176px;
  }
`;

const ChildSeatContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Icon = styled(FontAwesomeIcon)`
  color: #b9b9b9;
`;
const Span = styled.p`
  margin: 0px 8px;
  text-transform: capitalize;
  color: #b9b9b9;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 24px;
`;
const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BookButton = styled.button`
  border: none;
  width: 228px;
  height: 48px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00a76d;
  color: #ffffff;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  margin: 40px auto;
  cursor: pointer;
  :hover {
    color: #ffffff;
  }
  :disabled {
    opacity: 0.6;
  }
`;

const RangeContainer = styled.div<any>`
  opacity: ${p => (p.active === "1" ? "1" : "0.6")};
`;
/* <Date
          allowClear={false}
          size="large"
          getCalendarContainer={() =>
            document.getElementById("calendar") as HTMLElement
          }
        />
        <Calendar id="calendar" />
const Date = styled(DatePicker)`
  grid-area: date;
  width: 100%;
  input {
    display: flex;
    height: 49px;
    border-radius: 0;
    color: #b9b9b9;
    padding: 0px 24px;
    font-family: "Roboto";
    font-size: 16px;
    line-height: 24px;
  }
`;
const Calendar = styled.div`
  .ant-calendar {
    grid-area: service-type;
    top: 3px;
    border-radius: 0;
    border: 1px solid #e5e5e5;
  }
  .ant-calendar-input-wrap {
    display: flex;
    align-items: center;
    height: 49px;
    color: #b9b9b9;
    padding: 0px 24px;
    font-family: "Roboto";
    font-size: 16px;
    line-height: 24px;
  }
  .ant-calendar-input {
    color: #b9b9b9;
  }
`;
*/
