import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { WPImage } from '../../../interfaces';

type Props = {
    backgroundImage: WPImage,
    buttonLink: '',
    buttonText: '',
    description: '',
    title: '',
}

export default function BookNow({ backgroundImage, buttonLink, buttonText, description, title, }: Props) {
    return (
        <Container bgimgurl={backgroundImage.url}>
            <Content>
                <Title>{title}</Title>
                <Text dangerouslySetInnerHTML={{ __html: description }} />
                <Link to={buttonLink}>
                    <StyledButton>{buttonText}</StyledButton>
                </Link>
            </Content>
        </Container>
    );
};

const Container = styled.div<{ bgimgurl?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 407px;
  background: ${p => `url('${p.bgimgurl}') no-repeat center`};
  background-size: cover;
  padding: 16px;
  text-align: center;
`;
const Content = styled.div`
  max-width: 721px;
  margin: 0 auto;
`;
const Title = styled.h1`
  color: #ffffff;
  font-weight: bold;
  font-family: "Roboto";
  font-size: 40px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
const Text = styled.p`
  color: #ffffff;
  font-weight: normal;
  font-family: "Roboto";
  font-size: 16px;
  margin: 28px 0;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;
const StyledButton = styled.button`
  width: 120px;
  background: #00a76d;
  color: #ffffff;
  border: none;
  padding: 9px 16px;
  text-transform: uppercase;
  font-family: "Roboto";
  font-size: 16px;
  &:hover {
    cursor: pointer;
  }
`;
