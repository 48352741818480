import { createContext, Dispatch, SetStateAction } from "react";
import {
  serviceTypes,
  ServiceTypes
} from "../shared-components/content-blocks/TypesOfService/typesOfServiceData";

export interface BasketContextProps {
  services: ServiceTypes[];
  setServices: Dispatch<SetStateAction<ServiceTypes[]>>;
}

export const defaultBasketState: BasketContextProps = {
  services: serviceTypes,
  setServices: () => []
};

export const BasketContext = createContext<BasketContextProps>(
  defaultBasketState
);
