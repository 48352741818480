import React from "react";
import styled from "styled-components/macro";
import { Row, Col } from "antd";

import ecoLogo from "../../assets/images/footer/EcoDestinationManagementServices-NewLogo-Aruba .jpg";
import { BottomBar } from "./BottomBar";
import { Contact } from "./Contact";
export const Footer = () => {
  return (
    <Container>
      <Row justify="space-between" align="middle" gutter={[0, 40]}>
        <Col xs={{ span: 24 }} md={{ span: 11 }} lg={{ span: 8 }}>
          <LogoContainer />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
          <Contact />
        </Col>
        <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }}>
          <TosContainer>
            <TosText>Terms of conditions</TosText>
            <TosText>Privacy</TosText>
            <TosText>Site Map</TosText>
          </TosContainer>
        </Col>
      </Row>
      <BottomBar />
    </Container>
  );
};

const Container = styled.div`
  margin-top: 64px;
`;

const LogoContainer = styled.div`
  background: url('${ecoLogo}') no-repeat center;
  background-size: contain;
  width: 211px;
  height: 119px;
  margin: 0 auto;
`;
const TosContainer = styled.div`
  width: fit-content;
  margin: 0 auto;
`;
const TosText = styled.p`
  color: #3a3a3a;
  font-weight: normal;
  font-size: 16px;
  font-family: "Roboto";
  margin: 4px 0;
  text-align: left;
`;
