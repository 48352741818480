import React from 'react';
import FullscreenHero from './FullscreenHero/FullscreenHero';
import TextBlock from './Welcome/Welcome';
import Services from './TypesOfService/TypesOfService';
import FleetCarousel from './FleetCarousel/FleetCarousel';
import Fleet from './Fleet/Fleet';
import CallToAction from './BookNow/BookNow';
import Testimonials from './Testimonials/Testimonials';
import DualContent from './DualContent/DualContent';
import Hero from './Hero/Hero';

const blocks = {
    'hero': Hero,
    'fullscreenHero': FullscreenHero,
    'textBlock': TextBlock,
    'services': Services,
    'fleet': Fleet,
    'fleetCarousel': FleetCarousel,
    'callToAction': CallToAction,
    'testimonials': Testimonials,
    'dualContent': DualContent,
}

export default function ContentBlocks({ contentBlocks }) {
    return (
        <>
            {contentBlocks?.length > 0 && contentBlocks.map(({ acf_fc_layout, ...props }, i) => {
                const BlocksTemplate = blocks[acf_fc_layout] ? blocks[acf_fc_layout] : blocks['hero'];
                return <BlocksTemplate key={`content-block-row-${i}`} {...props} />
            })}
        </>
    )
}