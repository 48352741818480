import React from "react";
import styled from "styled-components";
import useSWR from 'swr';
import ContentBlocks from '../../shared-components/content-blocks';
import Loader from "../../shared-components/loading/loading";
import { apiUrls, fetcher } from "../../utils/apiUrls";

const HomeContainer = () => {

    const { data, error } = useSWR(apiUrls.home, fetcher);

    if (error) return <div>failed to load</div>
    if (!data) return <Loader />

    console.log(data);
    const { acf } = data.data;
    return (
        <Container>
            <ContentBlocks contentBlocks={acf.contentBlocks} />
        </Container>
    );
};
const Container = styled.div``;
export default HomeContainer;