import React from "react";
import styled from "styled-components/macro";

interface ConfirmationInputProps {
  title: string;
  value: string | number;
  position?: string;
}

export const ConfirmationInputWithTitle: React.FC<ConfirmationInputProps> = ({
  title,
  value,
  position
}) => {
  return (
    <Container style={{ gridArea: position }}>
      <ConfirmationInputTitle>{title}</ConfirmationInputTitle>
      <ConfirmationInput value={value} readOnly />
    </Container>
  );
};

const ConfirmationInputTitle = styled.h1`
  background: #016c6d;
  color: #ffffff;
  margin: 0;
  padding: 0px 0 0 24px;
  font-size: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  border: none;
`;
const ConfirmationInput = styled.input`
  color: #b9b9b9;
  padding: 0px 0 0 24px;
  height: 49px;
  width: 100%;
  text-transform: capitalize;
  border: none;
  font-family: "Roboto";
  font-size: 17px;
  line-height: 17px;
`;
const Container = styled.div``;
