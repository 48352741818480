import React from "react";
import styled from "styled-components/macro";
import { WPImage } from "../../../interfaces";

interface iHeroSection {
    gallery: Array<WPImage>,
    title: string,
}

export const Hero: React.FC<iHeroSection> = ({
    gallery,
    title,
}) => {
    return (
        <HeroContainer bgImg={gallery[0]}>
            <Title>
                {title}
                {/* <TitleGreen>CADILLAC ESCALADE 6PX</TitleGreen> */}
            </Title>
        </HeroContainer>
    );
};

const HeroContainer = styled.div<{ bgImg: WPImage }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 338px;
  background: ${p => `url('${p.bgImg.url}') no-repeat center center`};
  background-size: cover;
`;
const Title = styled.h1`
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
  font-family: "Roboto";
  color: #ffffff;
  @media (max-width: 660px) {
    text-align: center;
  }
`;
const TitleGreen = styled.span`
  color: #00a76d;
`;
