import React from 'react';
import styled from 'styled-components';
import Preloader from './preloader.gif';

const Container = styled.div`
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    width:100%;
    height:100vh;
`;

const Image = styled.img`
    width:150px;
    height:150px;
`;

const Loader = () => {
    return (
        <Container>
            <Image src={Preloader} alt="Eco Premier Transportation" />
        </Container>
    )
}
export default Loader;