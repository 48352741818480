import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { SingleFleetItem } from '../../../../interfaces';

export const FlippedCard: React.FC<SingleFleetItem> = ({ title, cardHighlightDescription, type, slug }) => {
    return (
        <Container>
            <Content>
                <Title>{title}</Title>
                <Text dangerouslySetInnerHTML={{ __html: cardHighlightDescription }} />
                <Button to={`/${type}/${slug}`}>view more</Button>
            </Content>
        </Container>
    );
};

const Container = styled.div`
  padding-top: 8px;
  display: flex;
  justify-content: center;
  background: #00a76d;
  height: 377px;

  &:hover {
    box-shadow: 0px 0px 9px 2px rgba(0, 0, 0, 0.75);
  }
`;
const Content = styled.div`
  max-width: 225px;
`;
const Title = styled.h1`
  text-transform: uppercase;
  color: #fff;
  text-align: center;

  font-size: 16px;
  line-height: 41px;
  font-family: "Roboto";
  font-weight: bold;
`;
const Text = styled.div`
  p{
    color: #fff;
    text-align: left;
  
    font-size: 12px;
    line-height: 14px;
    font-family: "Roboto";
    font-weight: normal;
  }
`;
const Button = styled(Link)`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  width: 228px;
  height: 48px;
  background: #ffffff;
  color: #00a76d;
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  font-family: "Roboto";
  font-weight: normal;
  &:hover {
    color: #00a76d;
  }
`;
