import React from "react";
import styled from "styled-components/macro";
import { EcoButton } from "../../../shared-components/button/Button";
import confetti from "../../../assets/images/Home Page/confeti.png";
import police from "../../../assets/images/Home Page/police.png";
import ring from "../../../assets/images/Home Page/ring.png";
import bubble from "../../../assets/images/Home Page/bubble.png";

const Icons: StringKeyObject = {
    'confetti': confetti,
    'police': police,
    'ring': ring,
    'bubble': bubble,
};

interface StringKeyObject {
    [key: string]: string;
}

interface ServiceItemProps {
    cost: string;
    description: string;
    title: string;
    type: string;
    button: boolean;
    checkbox?: boolean;
    active?: boolean;
}
export const ServiceItem: React.FC<ServiceItemProps> = ({
    cost,
    description,
    title,
    type,
    button,
    checkbox,
    active
}) => {
    console.log(title);
    return (
        <Container>
            {checkbox && active ? (
                <Check color="#00a76d" />
            ) : checkbox ? (
                <Check color="darkgrey" />
            ) : null}
            <StyledImage src={Icons[type] ? Icons[type] : Icons['confetti']} alt={title} />
            <Span>{cost}</Span>
            <Title>{title}</Title>
            <Text dangerouslySetInnerHTML={{ __html: description }} />
            {button && <EcoButton text="view details" />}
        </Container>
    );
};

const Container = styled.div`
  width: 250px;
  height: 300px;
  margin: 0 auto;
  background: #d6ddda;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
`;
const StyledImage = styled.img`
  width: 44px;
  height: 41px;
`;
const Span = styled.span`
  color: #00a76d;
  margin: 16px auto;
`;
const Title = styled.h1`
  font-weight: bold;
  font-size: 20px;
  font-family: "Roboto";
  color: #002c3a;
`;
const Text = styled.p`
  font-weight: normal;
  font-size: 14px;
  font-family: "Roboto";
  color: #676666;
  width: 190px;
  margin: 8px auto;
`;

const Check = styled.div`
  position: relative;
  top: -16px;
  right: 16px;
  height: 20px;
  width: 20px;
  background: ${props => props.color};
  align-self: flex-end;
`;
