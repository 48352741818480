import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components/macro";

import { vehicleSelectionData } from "../../fleet/fleetData";
import { FleetItem } from "../../../shared-components/content-blocks/Fleet/components/FleetItem";

import {
    serviceTypes,
    ServiceTypes
} from "../../../shared-components/content-blocks/TypesOfService/typesOfServiceData";
import { ServiceItem } from "../../../shared-components/content-blocks/TypesOfService/ServiceItem";
import { BookButton } from "./Overview/Overview";
import { APP_ROUTES } from "../../../config/router.constants";
import { Link, useParams, useHistory } from "react-router-dom";
import handleNextStep from "../utils/handleNextStep";
import { BasketContext } from "../../../config/basketContext";

export interface VehicleServicesProps {
    vehicle: string;
    services: ServiceTypes[];
}
export const initialVehicleState = {
    vehicle: "",
    services: []
};
export const VehicleServices: React.FC<{}> = () => {
    const basketState = useContext(BasketContext);
    const [activeServices, setActiveServices] = useState<string[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<string>();
    const [vehicleData, setVehicleData] = useState<VehicleServicesProps>(
        initialVehicleState
    );
    const { id } = useParams<{ id: string }>();
    const { img, name } = vehicleSelectionData[parseInt(id)];
    const history = useHistory();

    useEffect(() => {
        setSelectedVehicle(id);
        if (!localStorage.getItem("vehicleData")) {
            return;
        } else {
            const storageVehicleData = JSON.parse(
                localStorage.getItem("vehicleData")!
            );
            const active = storageVehicleData.services.map(
                (el: ServiceTypes) => el.id
            );
            setActiveServices(active);
            setVehicleData(storageVehicleData);
        }
    }, [id]);

    useEffect(() => {
        const services = activeServices.map(
            serviceId => (serviceTypes as any)[serviceId]
        );
        setVehicleData({ ...vehicleData, services });
        basketState.setServices(services);
    }, [activeServices, basketState, vehicleData]);

    useEffect(() => {
        const activeServices = basketState.services.map(service => service.id);
        if (activeServices.length !== basketState.services.length)
            setActiveServices(activeServices);
    }, [basketState.services]);

    const handleActiveService = async (service: string) => {
        !activeServices.includes(service)
            ? setActiveServices([...activeServices, service])
            : setActiveServices(
                activeServices.filter(serviceId => {
                    return serviceId !== service;
                })
            );
    };

    return (
        <Container>
            <Content>
                {/* <StyledFleetItem image={img} name={name} checkbox active /> */}
                <Link to={APP_ROUTES.BOOKING_VEHICLE}>
                    <Text>Choose a different vehicle</Text>
                </Link>

                <Title>extra services</Title>
            </Content>
            <GridContainer>
                {serviceTypes.map(service => (
                    <div key={service.id} onClick={() => handleActiveService(service.id)}>
                        <ServiceItem
                            type={service.icon}
                            title={service.name}
                            cost={service.price}
                            description={service.text}
                            button={false}
                            checkbox
                            active={activeServices.includes(service.id)}
                        />
                    </div>
                ))}
            </GridContainer>

            <BookButton
                onClick={() => {
                    handleNextStep("vehicleData", {
                        ...vehicleData,
                        vehicle: selectedVehicle
                    });
                    history.push(APP_ROUTES.BOOKING_CONFIRMATION);
                }}
            >
                next step
      </BookButton>
        </Container>
    );
};

const Container = styled.div`
  padding: 48px 8px;
  @media (max-width: 762px) {
    padding: 48px 0;
  }
`;
const Content = styled.div`
  max-width: 400px;
  margin: 50px auto;
`;
const StyledFleetItem = styled(FleetItem)`
  cursor: initial;
  max-width: 400px;
`;
const Text = styled.p`
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 17px;
  color: #b9b9b9;
  margin: 24px 0;
`;
const Title = styled.h1`
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  font-family: "Roboto";
  line-height: 17px;
  color: #434343;
  margin: 64px 0 72px 0;
`;
const GridContainer = styled.div`
  display: grid;

  grid-template-columns: repeat(3, auto);
  grid-gap: 24px;
  padding-bottom: 40px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, auto);
  }
  @media (max-width: 762px) {
    grid-gap: 12px 0px;
    grid-template-columns: repeat(1, 1fr);
  }
`;
