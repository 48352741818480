import React from "react";
import styled from "styled-components/macro";
import { Row, Col } from "antd";
import { WPImage } from "../../../interfaces";

interface iButtonLink {
    url: string,
    title: string,
}

interface iButton {
    buttonLink: iButtonLink,
    buttonClass: string,
}

interface iSlide {
    image: WPImage,
    prefix: string,
    subtitle: string,
    title: string,
    buttons: Array<iButton>,
}

interface iSlides {
    slides: Array<iSlide>
}

export default function FullscreenHero({ slides = [] }: iSlides) {
    const { buttons = [], image, prefix, subtitle, title, } = slides[0] || {};
    return (
        <Container>
            <Row>
                <Col
                    xs={{ span: 0 }}
                    lg={{ span: 24 }}
                    style={{ height: "230px" }}
                ></Col>
            </Row>
            <Row>
                <Col
                    xs={{ span: 22, offset: 1 }}
                    lg={{ span: 8, offset: 3 }}
                    xl={{ span: 6, offset: 4 }}
                    xxl={{ span: 6, offset: 7 }}
                    style={{ height: "389px" }}
                >
                    <TextContent>
                        <TextSpan>{prefix}</TextSpan>
                        <TypeSpan>{subtitle}</TypeSpan>
                        <ModelSpan>{title}</ModelSpan>
                        {buttons?.length > 0 &&
                            <ButtonContainer>
                                {buttons.map(({ buttonLink, buttonClass }, i) =>
                                    <StyledLink key={`hero-button-${i}`} href={buttonLink.url} className={buttonClass || ''}>
                                        {buttonLink.title}
                                    </StyledLink>
                                )}
                            </ButtonContainer>
                        }
                    </TextContent>
                </Col>
                <ImageColumn
                    bgimg={image}
                    xs={{ span: 22, offset: 1 }}
                    lg={{ span: 12, offset: 0 }}
                    xxl={{ span: 8, offset: 1 }}
                ></ImageColumn>
            </Row>
            <Row
                justify="center"
                align="middle"
                style={{ height: "105px" }}
            >
                <Col span={24}>
                    <CircleContainer>
                        <Circle />
                        <Circle />
                        <Circle />
                        <Circle />
                    </CircleContainer>
                </Col>
            </Row>
        </Container>
    );
};

const StyledLink = styled.a`
  background: #00a76d !important;
  font-size: 19px !important;
  font-weight: normal !important;
  margin: 16px 0 !important;
  padding: 8px 16px !important;

  height: 44px;
  color: #ffffff;
  border: none;
  /* button text styling */
  font-family: Roboto;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 12px;
  }
  &:hover {
    cursor: pointer;
    color:#fff;
  }
`;

const Container = styled.div`
  background: #e6e6e6;
`;
const TextContent = styled.div`
  height: inherit;
  padding: 24px 0;

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const TextSpan = styled.p`
  color: #676666;
  font-size: 19px;
  font-family: Roboto;
  font-weight: normal;
  text-transform: uppercase;
  margin: 0;
`;
const TypeSpan = styled.p`
  color: #959393;
  font-size: 40px;
  font-family: Roboto;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;
const ModelSpan = styled.p`
  color: #002c3a;
  font-size: 40px;
  font-family: Roboto;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  width: 296px;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const ImageColumn = styled(Col) <{ bgimg: { url: string } }>`
  height: 389px;
  background: ${p => `url('${p.bgimg?.url}') no-repeat center`};
  background-size: contain;

`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 320px;
  @media (max-width: 768px) {
    width: 250px;
  }
`;

const CircleContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const Circle = styled.div`
  background: #00a76d;
  width: 12px;
  height: 12px;
  margin: 6px;
  border-radius: 50%;
  opacity: 0.5;
`;
