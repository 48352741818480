import React from "react";
import styled from "styled-components/macro";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import carImage from "../../../assets/images/Fleet Single Page/Imagen 12.png";
import { APP_ROUTES } from "../../../config/router.constants";
import { WPImage } from "../../../interfaces";
interface iShowcaseSection {
    description: string,
    gallery: Array<WPImage>,
    title: string,
    type: string,
    trytnProductId:string,
    trytnProductType: string,
}

export const FleetSingleDetail: React.FC<iShowcaseSection> = ({
    description,
    title,
    gallery,
    type,
    trytnProductId,
    trytnProductType,
}) => {
    return (
        <Container>
            <Row justify="space-between" gutter={[0, 40]}>
                <Col
                    xs={{ span: 24 }}
                    sm={{}}
                    md={{ span: 10 }}
                    lg={{ span: 10 }}
                    xl={{ span: 10 }}
                    xxl={{}}
                >
                    <Content>
                        <Model>
                            <Type>{type}</Type> <br />
                            {title}
                        </Model>
                        <Text dangerouslySetInnerHTML={{ __html: description }} />
                        <Button 
                            href={`https://trytn.com/ECOPremierTransportation/details/${trytnProductId}?typeOfProduct=${trytnProductType}`}
                            className="book-now js-trytn-book trytn-button"
                        >
                            book now
                        </Button>
                    </Content>
                </Col>
                <Col
                    xs={{ span: 24 }}
                    sm={{}}
                    md={{ span: 14 }}
                    lg={{ span: 14 }}
                    xl={{ span: 14 }}
                    xxl={{}}
                >
                    <ImageContainer bgImage={gallery[0]} />
                </Col>
            </Row>
        </Container>
    );
};

// Second Column
const ImageContainer = styled.div<{ bgImage: WPImage }>`
  margin: 0 auto;
  height: 240px;
  max-width: 435px;
  background: ${p => `url("${p.bgImage.url}") no-repeat center center}`};
  background-size: contain;
  @media (max-width: 320px) {

   margin: 0 auto;
  }
`;
// First Column
const Container = styled.div`
  padding: 80px 0 0 0;
  @media (max-width: 767px) {
    text-align: center;
  }
`;
const Content = styled.div`
  width: fit-content;
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`;
const Type = styled.span`
  color: #959393;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto";
`;
const Model = styled.h1`
  color: #002c3a;
  font-size: 20px;
  font-weight: bold;
  font-family: "Roboto";
`;
const Text = styled.div`
  p{
    text-align: justify;
    color: #676666;
    max-width: 500px;
    min-width: 280px;
    font-size: 16px;
    font-weight: normal;
    font-family: "Roboto";
    @media (max-width: 767px) {
      width: 280px;
      text-align: center;
      margin: 40px auto;
    }
  }
`;
const Button = styled.a`

  /* padding: 10px 20px; */

  text-transform: uppercase;
  margin-top: 24px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  max-width: 130px;
  height: 48px;
  background: #00a76d !important;
  font-size: 16px !important;
  line-height: 19px !important;
  font-family: "Roboto";
  font-weight: normal !important;
  &:hover {
    cursor: pointer;
    color: #ffffff;
  }
  @media (max-width: 767px) {
    margin: 0 auto;
  }
`;
