import React, { useState } from "react";
import { validator } from "../../utils/validator";
import styled from "styled-components/macro";
import { Row, Col } from "antd";
import {
    ContactInterface,
    initialContactState,
    ContactErrors
} from "./utils/utils";

export const ContactForm = () => {
    const [contactInfo, setContactInfo] = useState<ContactInterface>(initialContactState);
    const [errors, setErrors] = useState<ContactErrors>({});
    const handleInput = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const key = e.currentTarget.name;
        const value = e.currentTarget.value;
        setContactInfo({ ...contactInfo, [key]: value });
    };

    const sendInfo = () => {
        // TODO: email service
        console.log(contactInfo);
    };

    return (
        <Row justify="center">
            <Col
                xs={{ span: 16 }}
                sm={{ span: 14 }}
                md={{ span: 10, offset: 0 }}
                lg={{ span: 10, offset: 0 }}
                xxl={{ span: 6, offset: 6 }}
            >
                <Content>
                    <LabelInputGroup>
                        <Label>full name</Label>
                        <Input
                            type="text"
                            placeholder="John Doe"
                            onChange={handleInput}
                            name="fullName"
                            value={contactInfo.fullName || ""}
                            isValid={errors.fullName}
                        />
                    </LabelInputGroup>
                    <LabelInputGroup>
                        <Label>email address</Label>
                        <Input
                            type="email"
                            placeholder="example@gmail.com"
                            onChange={handleInput}
                            name="email"
                            value={contactInfo.email || ""}
                            isValid={errors.email}
                        />
                    </LabelInputGroup>

                    <CityAndPhoneContainer>
                        <LabelInputGroup>
                            <Label>City</Label>
                            <CityInput
                                type="text"
                                placeholder="city name"
                                onChange={handleInput}
                                name="city"
                                value={contactInfo.city || ""}
                                isValid={errors.city}
                            />
                        </LabelInputGroup>

                        <LabelInputGroup>
                            <Label>phone number</Label>
                            <PhoneInput
                                type="text"
                                placeholder="+297 1234567"
                                onChange={handleInput}
                                name="phone"
                                value={contactInfo.phone || ""}
                                isValid={errors.phone}
                            />
                        </LabelInputGroup>
                    </CityAndPhoneContainer>
                </Content>
            </Col>
            <Col
                xs={{ span: 16 }}
                sm={{ span: 14 }}
                md={{ span: 9, offset: 1 }}
                lg={{ span: 9, offset: 1 }}
                xxl={{ span: 11, offset: 1 }}
            >
                <Content>
                    <LabelInputGroup>
                        <Label>message</Label>
                        <Textarea
                            name="message"
                            value={contactInfo.message || ""}
                            onChange={handleInput}
                            isValid={errors.message}
                        />
                    </LabelInputGroup>
                </Content>
                <ButtonContainer>
                    <Button
                        onClick={async () => {
                            const errors = await validator(contactInfo);
                            if (
                                Object.keys(errors).some(key => (errors as any)[key]) === true
                            ) {
                                return setErrors(errors);
                            }
                            sendInfo();
                        }}
                    >send</Button>
                </ButtonContainer>
            </Col>
        </Row>
    )
}

const LabelInputGroup = styled.div`
  margin: 22px 0;
`;
const Content = styled.div``;
const Label = styled.p`
  font-family: "Roboto";
  text-transform: uppercase;
  font-weight: bold;
  color: #434343;
  font-size: 9px;
  line-height: 11px;
  max-width: 452px;
`;
const Input = styled.input<{ isValid?: boolean }>`
  border: ${p => (p.isValid ? "1px solid red" : "1px solid #e5e5e5")};
  max-width: 452px;
  min-width: 187px;
  height: 56px;
  padding: 0 0 0 19px;
  text-transform: capitalize;
  font-family: Roboto;
  font-size: 16px;
`;
const CityInput = styled(Input)`
  @media (min-width: 1024px) {
    width: 187px;
  }
`;
const PhoneInput = styled(Input)`
  @media (min-width: 1024px) {
    width: 220px;
  }
`;
const CityAndPhoneContainer = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    justify-content: space-between;
    max-width: 452px;
  }
`;
const Textarea = styled.textarea<{ isValid?: boolean }>`
  max-width: 452px;
  min-height: 348px;
  border: ${p => (p.isValid ? "1px solid red" : "1px solid #e5e5e5")};
  @media (min-width: 1024px) {
    min-height: 275px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 452px;
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;
const Button = styled.button`
  height: 44px;
  min-width: 127px;
  background: #00a76d;
  color: #ffffff;
  border: none;
  /* button text styling */
  font-size: 19px;
  font-family: Roboto;
  font-weight: normal;
  text-transform: uppercase;
  margin: 16px 0;
  padding: 8px 16px;
  :disabled {
    opacity: 0.5;
  }
`;
