import { OverviewProps, initialOverviewState } from "../Overview/initialState";
import { VehicleServicesProps, initialVehicleState } from "../VehicleServices";
import {
  ConfirmationProps,
  initialConfirmationState
} from "../confirmation/initialState";

export interface PaymentProps
  extends OverviewProps,
    VehicleServicesProps,
    ConfirmationProps {
  ccNumber: string;
  cvv: string;
  expMonth: string;
  expYear: string;
}

export const initialPaymentState = {
  ...initialOverviewState,
  ...initialConfirmationState,
  ...initialVehicleState,
  ccNumber: "",
  cvv: "string",
  expMonth: "",
  expYear: ""
};

export interface PaymentErrors {
  ccNumber?: boolean;
  cvv?: boolean;
  expMonth?: boolean;
  expYear?: boolean;
}
