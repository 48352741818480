export const fetcher = (input: RequestInfo, init?: RequestInit) => fetch(input, init).then(res => res.json())
export const baseUrl = 'https://cms.eptaruba.com/';
export const apiPath = 'wp-json/ept/v1/';
export const apiUrls = {
    websiteInfo: `${baseUrl}${apiPath}object-data/website-info/false/website-info`,
    home: `${baseUrl}${apiPath}object-data/page/false/home`,
    about: `${baseUrl}${apiPath}object-data/page/false/about`,
    contact: `${baseUrl}${apiPath}object-data/page/false/contact`,
    services: `${baseUrl}${apiPath}object-data/page/false/services`,
    fleet: `${baseUrl}${apiPath}object-data/page/false/fleet`,
}
export const singlePost = (postType: string, slug: string, parent = false) => `${baseUrl}${apiPath}object-data/${postType}/${parent}/${slug}`