export enum APP_ROUTES {
  HOME = "/",
  SERVICES = "/services",
  ABOUT = "/about",
  CONTACT = "/contact",
  FLEET = "/fleet",
  BOOKING = "/booking",
  BOOKING_VEHICLE = "/booking/vehicle",
  BOOKING_CONFIRMATION = "/booking/confirmation",
  BOOKING_PAYMENT = "/booking/payment"
}
