import React from "react";
import styled from "styled-components/macro";

import { Hero } from "./components/Hero";
import { FleetSingleDetail } from "./components/FleetSingleDetail";
// import { Advantage } from "./components/Advantage";
// import { ImagesDisplay } from "./components/ImagesDisplay";
import useSWR from "swr";
import { singlePost, fetcher } from "../../utils/apiUrls";
import Loading from "../../shared-components/loading/loading";

export const FleetSingleContainer = () => {

    var url = window.location.href.replace(/\/$/, '');
    var slug = url.substr(url.lastIndexOf('/') + 1);

    const { data, error } = useSWR(singlePost('fleet', slug), fetcher);

    if (error) return <div>failed to load</div>
    if (!data) return <Loading />

    const { acf } = data.data;
    const {
        heroSection,
        showcaseSection,
        trytnProductId,
        trytnProductType,
    } = acf;
    return (
        <Container>
            <Hero {...heroSection} />
            <Content>
                <FleetSingleDetail {...showcaseSection} trytnProductId={trytnProductId} trytnProductType={trytnProductType} />
                {/* <Advantage />
                <ImagesDisplay /> */}
            </Content>
        </Container>
    );
};

const Container = styled.div``;
const Content = styled.div`
  padding: 0 5%;
`;
